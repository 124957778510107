<template>
  <div>
    <div class="edit-info-container">
      <header-back rootClassName="rootClassName"></header-back>
      <form class="edit-info-container1" @submit.prevent="createPassword">
        <h1 class="edit-info-text">Edit Password</h1>
        <div v-if="isError" @click="closeMsg()" class="w-full mb-5">
          <XClose :text="msg_error" />
        </div>
        <div v-if="isSukses" @click="closeSukses()" class="w-full mb-5">
          <XSukses :text="msg_sukses" />
        </div>
        <input-box
          rootClassName="rootClassName1"
          text="New Password"
          textinputPlaceholder="min. 8 characters"
          type="text"
          v-model="password"
        ></input-box>
        <input-box
          rootClassName="rootClassName2"
          text="Confirmed New Password"
          textinputPlaceholder="min. 8 characters"
          type="text"
          v-model="password_confirmation"
        ></input-box>
        <button type="submit" class="edit-info-btn-login">
          <span class="edit-info-text1">{{
            isSubmiting ? 'Updating..' : 'Save New Password'
          }}</span>
        </button>
      </form>
    </div>
    <Footer />
  </div>
</template>

<script>
  import HeaderBack from '../components/header-back.vue';
  import InputBox from '../components/input-box.vue';
  import Footer from '../components/footer';
  import Api from '../api';
  import XClose from '../components/x-close';
  import XSukses from '../components/x-sukses';
  import axios from 'axios';
  export default {
    name: 'EditPassword',
    props: {},
    data() {
      return {
        password: null,
        password_confirmation: null,
        isError: false,
        msg_error: null,
        isSukses: false,
        msg_sukses: null,
        isSubmiting: false,
      };
    },
    components: {
      HeaderBack,
      InputBox,
      Footer,
      XClose,
      XSukses,
    },
    methods: {
      closeMsg() {
        this.msg_error = null;
        this.isError = false;
      },
      openMsg(msg) {
        this.msg_error = msg;
        this.isError = true;
      },
      closeSukses() {
        this.msg_sukses = null;
        this.isSukses = false;
      },
      async createPassword() {
        try {
          this.isSubmiting = true;
          if (!this.password || !this.password_confirmation) {
            this.isError = true;
            this.msg_error = 'Password belum lengkap';
          } else {
            const content = await axios.post(`${Api.updatePasswordUrl}`, {
              password: this.password,
              password_confirmation: this.password_confirmation,
            });
            if (content.data.status === 200) {
              this.msg_sukses = 'Changes saved';
              this.isSukses = true;
              this.password = null;
              this.password_confirmation = null;
            }

            if (content.data.code === 400) {
              this.msg_error = content.data.errors.password[0];
              this.isError = true;
            }
          }
        } catch (error) {
          this.msg_error = error.response.data.message;
          this.isError = true;
        } finally {
          this.isSubmiting = false;
        }
      },
    },
  };
</script>

<style scoped>
  .alert-err {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: 300px;
    z-index: 10000;
  }
  .edit-info-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: #eae3d9;
    padding-bottom: 46px;
  }
  .edit-info-container1 {
    width: 492px;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .edit-info-text {
    color: #1f252c;
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
  }
  .edit-info-btn-login {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 64px;
    display: flex;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
    background-color: #1f252c;
  }
  .edit-info-text1 {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 2.4px;
  }
  @media (max-width: 991px) {
    .edit-info-container1 {
      width: 100%;
      margin-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
  }
</style>
